<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="gaojishezhi">
        <p class="tit"><span class="span1">*</span>审批人去重</p>
        <div class="xuanze_shenpi_ren">
            <el-select v-model="four_zhi.shifou">
                <el-option
                    v-for="(i,index) in shi_fo"
                    :key="index"
                    :label="i.label"
                    :value="i.value">
                </el-option>
            </el-select>
            自动去重规则说明
        </div>
        <span class="faqiren" @click="dianji_faqiren">
            <img :src="four_zhi.faqiren_zidong_tongguo?require('../assets/kuang_duigou.png'):require('../assets/kaung_kongxin.png')" alt="">
            发起人审批时自动通过
        </span>
        <p class="tit">审批意见填写提示<span class="span2">最多100字</span></p>
        <input type="text" v-model="four_zhi.yijian" placeholder="请输入" class="wai">
        <span class="faqiren" @click="dianji_shenpi_yijian">
            <img :src="four_zhi.shenpi_yijian?require('../assets/kuang_duigou.png'):require('../assets/kaung_kongxin.png')" alt="">
            必填审批意见
        </span>
    </div>
</template>

<script>
export default {
  name: 'gaojishezhi',
  data () {
    return {
      text: ['默认模板', '自定义打印模板'],
      shi_fo: [
        {
          label: '同一个审批人在流程中出现多次时，仅保留第一个',
          value: '1'
        },
        {
          label: '同一个审批人仅在连续出现时，自动去重',
          value: '2'
        },
        {
          label: '不启用自动去重',
          value: '3'
        }
      ],
      four_zhi: {
        yijian: '',
        shifou: '3',
        faqiren_zidong_tongguo: false,
        dayin_num: '0',
        shenpi_yijian: false
      }
    }
  },
  created () {
    if (this.$bendi_naqu_cunchu('mlbb_four_shezhi', '暂时') != undefined) {
      this.four_zhi = this.$bendi_naqu_cunchu('mlbb_four_shezhi', '暂时')
    } else {
      this.$bendi_cunfang_cunchu('mlbb_four_shezhi', this.four_zhi, '暂时')
    }
  },
  mounted () {
  },
  watch: {
    four_zhi: {
      handler (newValue, oldValue) {
        this.$emit('fourzhi', newValue)
        console.log(newValue)
        this.$bendi_cunfang_cunchu('mlbb_four_shezhi', newValue, '暂时')
      },
      deep: true
    }
  },
  methods: {
    dianji_faqiren () {
      this.four_zhi.faqiren_zidong_tongguo = !this.four_zhi.faqiren_zidong_tongguo
    },
    // 点击切换打印模板
    dianji_qiehuan_dayin (index) {
      this.four_zhi.dayin_num = index
    },
    // 是否必填审批意见
    dianji_shenpi_yijian () {
      this.four_zhi.shenpi_yijian = !this.four_zhi.shenpi_yijian
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.gaojishezhi {
    width:7.85rem;
    background:#fff;
    padding: 0.22rem 0.55rem;
    height:6.93rem;
    .tit{
        font-size: 0.14rem;
        color:#1A2533;
        margin-bottom: 0.16rem;
        .span1{
            color:#F96C6C;
        }
        .span2{
            color:#CCCCCC;
            font-size: 0.12rem;
            margin-left: 0.11rem;
        }
    }
    .xuanze_shenpi_ren{
        font-size: 0.14rem;
        color:#1B70FF;
        margin-bottom: 0.12rem;
    }
    .faqiren{
        display: flex;
        align-items: center;
        font-size: 0.12rem;
        color:#4C4A4D;
        margin-bottom: 0.29rem;
        cursor: pointer;
        img{
            height:0.14rem;
            width:0.14rem;
            margin-right: 0.07rem;
        }
    }
    .chaosongren{
        font-size: 0.14rem;
        color:#4C4A4D;
        span{
            font-size: 0.14rem;
            color:#1B70FF;
            cursor: pointer;
        }
        margin-bottom: 0.38rem;
    }
    .wai{
        border:0.01rem solid #CCCCCC;
        border-radius: 0.06rem;
        padding: 0 0.17rem;
        width:6.56rem;
        height:0.44rem;
        margin-bottom: 0.31rem;
    }
    .dayin_xuanze{
        display: flex;
        align-items: center;
        li{
            display: flex;
            align-items: center;
            font-size: 0.12rem;
            color:#4C4A4D;
            cursor: pointer;
            img{
                height:0.17rem;
                width:0.17rem;
                margin-right: 0.08rem;
            }
            &:nth-child(2){
                margin-left: 1.21rem;
            }
        }
    }
}
</style>
