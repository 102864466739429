<template>
    <div class="wf-dragging-proxy" v-bind:style="cc">
        <div class="proxy-icon">
            <i class="widgeticon" :class="componentName"></i>
        </div>
        <span class="proxy-name">{{componentText}}</span>
    </div>
</template>
<script>
export default {
  name: 'dragging',
  data: function () {
    return {
      componentName: '',
      componentText: '',
      cc: {},
      isstart: false
    }
  },
  created: function () {
    const self = this
    drag.$on('movestart', function (obj) {
      self.cc = Object.assign({}, self.cc, {
        display: 'none',
        top: obj.clientY + 'px',
        left: obj.clientX + 'px'
      })
      self.componentName = obj.componentName
      self.componentText = obj.componentText
      self.isstart = true
    })
    drag.$on('move', function (obj) {
      if (!self.isstart) {
        return
      }
      const clientX = obj.clientX
      const clientY = obj.clientY
      const startX = parseInt(self.cc.left)
      const startY = parseInt(self.cc.top)
      const moveX = clientX - startX + 'px'
      const moveY = clientY - startY + 'px'
      self.cc = Object.assign({}, self.cc, {
        display: 'block',
        top: startY + 'px',
        left: startX + 'px',
        transform: 'translate3d(' + moveX + ',' + moveY + ',0)'
      })
    })
    drag.$on('dragend', function (obj) {
      self.isstart = false
      const startX = self.cc.left
      const startY = self.cc.top
      self.cc = Object.assign({}, self.cc, {
        display: 'none',
        top: startY + 'px',
        left: startX + 'px'
      })
      document.querySelector('html').classList.remove('wf-cursor-move')
    })
  }
}
</script>
