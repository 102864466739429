<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="biaodan">
        <div class="wf-wrapper" v-on:mouseup="moveend" v-on:mousemove="move">
            <div class="wf-main">
                <mainleft></mainleft>
                <formcanvas></formcanvas>
                <setting></setting>
            </div>
            <dragging></dragging>
        </div>
    </div>
</template>

<script type="text/ecmascript-6">
import mainleft from '../components/mainLeft'
import formcanvas from '../components/formcanvas'
import setting from '../components/setting'
import dragging from '../components/dragging'

export default {
  name: 'app',
  components: {
    mainleft,
    formcanvas,
    setting,
    dragging
  },
  data: function () {
    return {
      isstart: false,
      componentView: {}
    }
  },
  methods: {
    move: function (e) {
      if (this.isstart) {
        document.querySelector('html').classList.add('wf-cursor-move')
        const obj = {
          componentName: this.componentView.componentName,
          clientX: e.clientX,
          clientY: e.clientY
        }
        drag.$emit('moveInCanvas', obj)
        drag.$emit('move', e)
      }
    },
    moveend: function (e) {
      if (this.isstart) {
        const obj = {
          componentView: this.componentView
        }
        drag.$emit('moveend', obj)
        this.isstart = false
      }
    }
  },
  created: function () {
    const self = this
    drag.$on('movestart', function (obj) {
      self.isstart = true
      self.componentView = obj.componentView
    })
  },
  mounted: function () {

  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import "../style/design.css";
.biaodan {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
*{
  -webkit-user-select:none;
  -moz-user-select:none;
}
</style>
