<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="shezhi">
        <p class="tit">
            <span>*</span>审批名称<s>最多50字</s>
        </p>
        <input type="text" v-model="one_zhi.shenpi_mingcheng" placeholder="请输入" maxlength="50" class="wai">
        <p class="tit">
            谁可以管理这个模板并导出数据<s>只有模版管理员可以编辑这个审批单和导出数据</s>
        </p>
        <input type="text" class="wai" placeholder="请输入"  @click="dakai_bumen" v-model="one_zhi.shei_keyi_guanli">
        <p class="tit">
            审批说明<s>最多100字</s>
        </p>
        <input class="wai" placeholder="请输入" type="text" maxlength='100' v-model="one_zhi.shenpi_shuoming">
        <p class="tit">
            选择分组
        </p>
        <el-select v-model="one_zhi.flow_group_id" placeholder="请选择">
            <el-option
                v-for="item in title"
                :key="item.flow_group_id"
                :label="item.name"
                :value="item.flow_group_id">
            </el-option>
        </el-select>
        <p class="tit" v-if="zhanshi_tubiao">
            图标设置
        </p>
        <div v-if="zhanshi_tubiao" class="fieldblock">
            <div class="wf-iconselect">
                <label @click="dianji_huan_tubiao(index)" :data-index="index" :data-icon="item.value" v-for="(item,index) in icons"
                        :key="index"
                        class="iconitem"
                        v-bind:class="index==selected?'seltected':''">
                    <i class="icon icon-checked" v-if="index==selected"></i>
                    <img :src="item.value">
                </label>
            </div>
        </div>
        <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="qiye_bumen">
            <div class="tiao_bumen">
                <div class="xuanze">
                    <p class="tit">选择：</p>
                    <div class="xuanze_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" @keyup="keyu" v-model="text">
                    </div>
                    <div class="mianbaoxie">
                        <div v-for="(i,index) in mianbao_list" :key='index'  @click="mianbao_dian(i,index)">
                            <p :class="mianbao_list.length-1==index?'p1':'p2'">{{i.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="quanxuan" v-if="qiye_renyuan_list.length!=0">
                        <!-- <input type="checkbox" @click="dianji_quanxuan" ref='quan'> -->
                        <img @click="dianji_quanxuan" :src="renyuan_quanxuan?require('../assets/okblue.png'):require('../assets/noccc.png')" alt="">
                        <span>全选</span>
                    </div>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(i,index) in qiye_bumen_list" :key="index" @click="dianji_bumen(i)">
                                    <p style="margin-left:0.16rem;">{{i.dept_name}}</p>
                                    <!-- <img class="img1" :src="i.child_flag=='0'?require('../assets/chazi.png'):require('../assets/chazi_ok.png')" alt=""> -->
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(i,index) in qiye_renyuan_list" :key="index" style='justify-content:left;margin-left:-0.07rem;'>
                                    <!-- <input ref="have" type="checkbox" @click="dianji_xuanren(i,$event,index)"> -->
                                    <img class="img2" @click="dianji_xuanren(i)" :src="i.zhi?require('../assets/okblue.png'):require('../assets/noccc.png')" alt="">
                                    <p>{{i.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="yixuan">
                    <p class="tit">已选：</p>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(i,index) in qiye_renyuan_list_xuan" :key="index">
                                    <p>{{i.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="dianji_queding">确定</p>
                <p class="quxiao" @click="qiye_bumen = false">取消</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { query_flow_group_info_list, get_staff_list_like, query_ent_dept_list, query_dept_staff_list } from '../api/api'
export default {
  name: 'shezhi',
  data () {
    return {
      one_zhi: {
        shenpi_mingcheng: '',
        shei_keyi_guanli: '',
        shei_keyi_guanli_id: '',
        shenpi_shuoming: '',
        img: 'https://www.mlcbr.com/images/logo/tb1.png',
        flow_group_id: '1'
      },
      qiye_bumen: false,
      mianbao_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      qiye_bumen_list: [],
      qiye_renyuan_list: [],
      qiye_renyuan_list_xuan: [],
      ziguanliyuan_bei: [],
      renyuan_quanxian_list: [],
      hang: '1',
      text: '',
      renyuan_quanxuan: false,
      icons: [
        {
          value: 'https://www.mlcbr.com/images/logo/tb1.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb2.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb3.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb4.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb5.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb6.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb7.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb8.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb9.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb10.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb11.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb12.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb13.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb14.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb15.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb16.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb17.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb18.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb19.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb20.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb21.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb22.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb23.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb24.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb25.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb26.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb27.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb28.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb29.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb30.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb31.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb32.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb33.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb34.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb35.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb36.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb37.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb38.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb39.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb40.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb41.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb42.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb43.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb44.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb45.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb46.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb47.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb48.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb49.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb50.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb51.png'
        },
        {
          value: 'https://www.mlcbr.com/images/logo/tb52.png'
        }
      ],
      selected: '0',
      zhanshi_tubiao: false,
      title: []
    }
  },
  created () {
    this.jichu()
    this.mianbao_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
    if (this.$bendi_naqu_cunchu('mlbb_one_shezhi', '暂时') != undefined) {
      this.one_zhi = this.$bendi_naqu_cunchu('mlbb_one_shezhi', '暂时')
      this.icons.forEach((item, index) => {
        if (item.value == this.one_zhi.img) {
          this.selected = index
        }
      })
    }
  },
  mounted () {
  },
  watch: {
    one_zhi: {
      handler (newValue, oldValue) {
        this.$emit('onezhi', newValue)
        this.$bendi_cunfang_cunchu('mlbb_one_shezhi', newValue, '暂时')
      },
      deep: true
    }
  },
  methods: {
    jichu () {
      const biaodan_id = (this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi', '暂时') != undefined && this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi', '暂时').flow_id != undefined) ? this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi', '暂时').flow_id : ''
      if (biaodan_id == 'VMCizuVOXb' ||
                biaodan_id == 'He5ln0bdDO' ||
                biaodan_id == 'qIwxmsMTgv' ||
                biaodan_id == 'Eb3n7CKQzi' ||
                biaodan_id == 'PLOQ9ku3Ip' ||
                biaodan_id == 'GBh7N2jMsi' ||
                biaodan_id == 'cvRyWGiUQH' ||
                biaodan_id == '7TvJHPyovM' ||
                biaodan_id == 'Z7VDOK9HGu' ||
                biaodan_id == '453y6pLD52' ||
                biaodan_id == 'H49d4edc3T' ||
                biaodan_id == 'Xsk2pLBS9a' ||
                biaodan_id == 'u1SCMMoUwK' ||
                biaodan_id == 'vY9ubhTO89' ||
                biaodan_id == 'fZ0x5qedzn' ||
                biaodan_id == 'hXstgwxhzh' ||
                biaodan_id == 'AY3cli7BAb' ||
                biaodan_id == '5FotYgnu0Z'
      ) {
        this.zhanshi_tubiao = false
      } else {
        this.zhanshi_tubiao = true
      }
      query_flow_group_info_list({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          date.forEach(item => {
            this.$set(item, 'name', item.flow_group_name)
          })
          this.title = date
        } else if (res.data.code == 500) {}
      })
    },
    dianji_huan_tubiao (index) {
      this.selected = index
      this.one_zhi.img = this.icons[index].value
    },
    qiye_bumen() {
      this.qiye_bumen = false
    },
    // 部门列表   自己的
    bumen_liebiao () {
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1',
          parent_id: this.mianbao_list[this.mianbao_list.length - 1].dept_id
        }
      }).then(res => {
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          this.qiye_bumen_list = date
        } else if (res.data.code == 10169) {
        }
      })
      query_dept_staff_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          dept_id: this.mianbao_list[this.mianbao_list.length - 1].dept_id,
          active: '1'
        }
      }).then(ras => {
        if (ras.data.code == 10149) {
          const listdata = JSON.parse(ras.data.body.data)
          listdata.map(item => { item.zhi = false })
          this.qiye_renyuan_list = listdata
          this.panduan_yixuan()
        } else if (ras.data.code == 10150) {
        }
      })
    },
    dakai_bumen () {
      this.mianbao_list.splice(1, this.mianbao_list.length)
      this.bumen_liebiao()
      this.qiye_bumen = true
    },
    // 点击全选
    dianji_quanxuan () {
      this.renyuan_quanxuan = !this.renyuan_quanxuan
      if (this.renyuan_quanxuan) {
        this.qiye_renyuan_list.map(item => {
          item.zhi = true
          this.qiye_renyuan_list_xuan.push(item)
        })
        this.qiye_renyuan_list_xuan = this.$func.Es5duplicate(this.qiye_renyuan_list_xuan, 'staff_id')
      } else {
        this.qiye_renyuan_list.map(item => { item.zhi = false })
        for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
          for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
            if (this.qiye_renyuan_list[i].staff_id == this.qiye_renyuan_list_xuan[a].staff_id) {
              this.qiye_renyuan_list_xuan.splice(a, 1)
            }
          }
        }
      }
    },
    panduan_yixuan () {
      const list = []
      for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
        for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
          if (this.qiye_renyuan_list[i].staff_id == this.qiye_renyuan_list_xuan[a].staff_id) {
            this.qiye_renyuan_list[i].zhi = true
            list.push('1111')
          }
        }
      }
      if (this.qiye_renyuan_list.length != 0) {
        if (list.length == this.qiye_renyuan_list.length) {
          this.renyuan_quanxuan = true
        } else {
          this.renyuan_quanxuan = false
        }
      }
    },
    dianji_xuanren (i) {
      i.zhi = !i.zhi
      if (i.zhi) {
        this.qiye_renyuan_list_xuan.push(i)
      } else {
        for (let a = 0; a < this.qiye_renyuan_list_xuan.length; a++) {
          if (i.staff_id == this.qiye_renyuan_list_xuan[a].staff_id) {
            this.qiye_renyuan_list_xuan.splice(a, 1)
          }
        }
      }
      this.panduan_shifou_quanxuan()
    },
    panduan_shifou_quanxuan () {
      const list = []
      console.log(this.qiye_renyuan_list)
      for (let i = 0; i < this.qiye_renyuan_list.length; i++) {
        if (this.qiye_renyuan_list[i].zhi) {
          list.push('1111')
        }
      }
      if (this.qiye_renyuan_list.length != 0) {
        if (list.length == this.qiye_renyuan_list.length) {
          this.renyuan_quanxuan = true
        } else {
          this.renyuan_quanxuan = false
        }
      }
    },
    youce_guanlian_zuoce () {
      for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
        for (let a = 0; a < this.qiye_renyuan_list.length; a++) {
          if (this.qiye_renyuan_list[a].staff_id == this.qiye_renyuan_list_xuan[i].staff_id) {
            this.qiye_renyuan_list[a].zhi = true
          }
        }
      }
      this.panduan_shifou_quanxuan()
    },
    // 点击部门
    dianji_bumen (i) {
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          parent_id: i.dept_id,
          active: '1'
        }
      }).then(res => {
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          this.qiye_bumen_list = date
          this.mianbao_list.push({ name: i.dept_name, dept_id: i.dept_id })
          this.inde = -1
          if (!this.bumen) {
            query_dept_staff_list({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                dept_id: i.dept_id,
                active: '1'
              }
            }).then(ras => {
              if (ras.data.code == 10149) {
                const listdata = JSON.parse(ras.data.body.data)
                listdata.forEach(item => {
                  item.zhi = false
                })
                this.qiye_renyuan_list = listdata
                this.youce_guanlian_zuoce()
              } else if (ras.data.code == 10150) {
              }
            })
          }
        } else if (res.data.code == 10169) {
        }
      })
    },
    // 点击面包屑进行页面内容切换
    mianbao_dian (i, index) {
      if (this.mianbao_list.length - 1 != index) {
        query_ent_dept_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            parent_id: i.dept_id,
            active: '1'
          }
        }).then(res => {
          if (res.data.code == 10168) {
            var date = JSON.parse(res.data.body.data)
            this.qiye_bumen_list = date
            this.mianbao_list.splice(index + 1, this.mianbao_list.length)
            query_dept_staff_list({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id,
                dept_id: i.dept_id,
                active: '1'
              }
            }).then(ras => {
              if (ras.data.code == 10149) {
                const listdata = JSON.parse(ras.data.body.data)
                console.log(99999, listdata)
                listdata.map(item => { item.zhi = false })
                this.qiye_renyuan_list = listdata
                this.youce_guanlian_zuoce()
              } else if (ras.data.code == 10150) {
              }
            })
          } else if (res.data.code == 10169) {
          }
        })
      }
    },
    // 点击确定
    dianji_queding () {
      const list = []
      const id = []
      console.log(this.qiye_renyuan_list_xuan)
      for (let i = 0; i < this.qiye_renyuan_list_xuan.length; i++) {
        list.push(this.qiye_renyuan_list_xuan[i].name)
        id.push(this.qiye_renyuan_list_xuan[i].staff_id)
        this.ziguanliyuan_bei.push(this.qiye_renyuan_list_xuan[i])
      }
      this.one_zhi.shei_keyi_guanli = list.join(',')
      this.one_zhi.shei_keyi_guanli_id = id.join(',')
      this.qiye_bumen = false
    },
    keyu () {
      if (this.text != '') {
        get_staff_list_like({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            staff_name: this.text,
            active: '1'
          }
        }).then(res => {
          if (res.data.code == 10027) {
            const date = JSON.parse(res.data.body.data)
            date.forEach(item => {
              this.$set(item, 'name', item.staff_name)
            })
            this.qiye_renyuan_list = date
          } else if (res.data.code == 10028) {
          }
        })
      } else {
        this.text = null
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.shezhi {
    width:7.85rem;
    background:#fff;
    padding: 0.22rem 0.55rem;
    height:6.93rem;
    .tit{
        font-size: 0.14rem;
        color:#1A2533;
        margin-bottom: 0.17rem;
        span{
            color:#F96C6C;
        }
        s{
            font-size: 0.12rem;
            color:#CCCCCC;
            padding-left: 0.17rem;
        }
    }
    .wai{
        border:0.01rem solid #CCCCCC;
        border-radius: 0.06rem;
        padding: 0 0.17rem;
        width:6.56rem;
        height:0.44rem;
        margin-bottom: 0.31rem;
    }

}
.fieldblock {
  display: block;
  margin: 0.05rem 0;
  line-height: normal;
}
.wf-iconselect {
  overflow: auto;
  height: 2.3rem;
  label{
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    -webkit-user-select: none; /* Chrome, Opera, Safari */
    -moz-user-select: none; /* Firefox 2+ */
    -ms-user-select: none; /* IE 10+ */
    user-select: none; /* Standard syntax */
  }
}
.wf-iconselect .iconitem {
  width: 0.6rem;
  height: 0.6rem;
  display: block;
  float: left;
  margin: 0.05rem;
  border-radius: 0.05rem;
  cursor: pointer;
  border: 1px solid transparent;
  overflow: hidden;
  position: relative;
}
.wf-iconselect .iconitem.selected {
  border-color: #3f9af9;
}
.wf-iconselect .iconitem img {
  max-width: 50%;
  max-height: 50%;
  padding: 0.1rem;
  background: #fff;
  border:1px solid #3f9af9;
  border-radius: 30%;
}
.wf-iconselect .iconitem .icon {
  position: absolute;
  display: block;
  border-radius: 50%;
  bottom: 1px;
  right: 1px;
  color: #fff;
  padding: 0.03rem 0.02rem 0.02rem 0.03rem;
  font-size: 14px;
  background: #3f9af9;
}
.tiao_bumen{
    display: flex;
    align-items: center;
    justify-content:center;
    .xuanze,.yixuan{
        height:3.54rem;
        width:3.85rem;
        background: #E7E8EB;
        margin: 0 0.11rem;
        margin-top: 0.2rem;
        position: relative;
        overflow-y:auto;
        &::-webkit-scrollbar {
            width: 0.2rem;
            height: 0.08rem;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 0.12rem;
            border: 0.06rem solid rgba(0, 0, 0, 0);
            box-shadow: 0.08rem 0 0 #A5ADB7 inset;
        }

        &::-webkit-scrollbar-thumb:hover {
            box-shadow: 0.08rem 0 0 #4A4A4A inset;
        }
        .el-tree{
            background: none;
        }
        .tit{
            position: absolute;
            left:0;
            top:-0.3rem;
            font-size: 0.14rem;
            color:#1A2533;
        }
        .xuanze_sousuo{
            position: relative;
            i{
                position: absolute;
                top:0.09rem;;
                left: 0.32rem;
            }
            input{
                height:0.3rem;
               
                width:2.74rem;
                border:none;
                background:#fff;
                padding: 0 0.53rem;
                margin: 0 auto;
                display: block;
                margin-top: 0.15rem;
                border-radius:0.02rem;
            }
        }
        .mianbaoxie{
            display: flex;
            // align-items: center;
            flex-wrap: wrap;
            margin-bottom: 0.1rem;
            margin-left: 0.23rem;
            margin-top:0.12rem;
            div{
                display: flex;
                align-items: center;
                cursor:pointer;
                p{
                    font-size: 0.14rem;
                }
                .p2{
                    color:#F96C6C;
                }
                .p1{
                    color:#1A2533;
                }
            }
        }
        .quanxuan{
            display: flex;
            align-items: center;
            margin-left: 0.24rem;
            input{
                -webkit-appearance: none;
                width: 0.18rem!important;
                height: 0.17rem!important;
                display: inline-block;
                margin: -0.03rem 0.05rem 0 0!important;
                cursor: pointer;
                vertical-align: middle;
                background:url(../assets/noccc.png) no-repeat;
                &:checked {
                    background: url(../assets/okblue.png) no-repeat;
                }
                outline-color:none;
                outline-style :none;
                outline-width:none;
            }
            span{
                color:#1A2533;
                font-size: 0.12rem;
            }
        }
        .nei_box{
            width:3.45rem;
            margin: 0 auto;
            max-height: 2.4rem;
            overflow:auto;
            // overflow-y: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 0.19rem;
                height: 0;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0.12rem;
                border: 0.06rem solid rgba(0, 0, 0, 0);
                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
            }

            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 0.08rem 0 0 #4A4A4A inset;
            }
            .liebiao{
                width:3.25rem;
                margin: 0 auto;
                ul{
                    li{
                        display: flex;
                        height:0.3rem;
                        justify-content: space-between;
                        align-items: center;
                        color:#1A2533;
                        font-size: 0.12rem;
                        padding-left: 0.1rem;
                        cursor:pointer;
                        &:hover{
                            background: #eee;
                        }
                        .img1{
                            height:0.11rem;
                            width:0.2rem;
                        }
                        input{
                            -webkit-appearance: none;
                            width: 0.18rem!important;
                            height: 0.17rem!important;
                            display: inline-block;
                            margin: -0.03rem 0.05rem 0 0!important;
                            cursor: pointer;
                            vertical-align: middle;
                            background:url(../assets/noccc.png) no-repeat;
                            &:checked {
                                background: url(../assets/okblue.png) no-repeat;
                            }
                            outline-color:none;
                            outline-style :none;
                            outline-width:none;
                        }
                    }
                }
            }
        }
    }
    .yixuan{
        .nei_box{
            width:3.45rem;
            margin: 0 auto;
            max-height: 3.5rem;
            overflow:auto;
            // overflow-y: hidden;
            overflow-y: scroll;
            &::-webkit-scrollbar {
                width: 0.19rem;
                height: 0;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 0.12rem;
                border: 0.06rem solid rgba(0, 0, 0, 0);
                box-shadow: 0.08rem 0 0 #A5ADB7 inset;
            }

            &::-webkit-scrollbar-thumb:hover {
                box-shadow: 0.08rem 0 0 #4A4A4A inset;
            }
            .liebiao{
                width:3.25rem;
                padding: 0.2rem 0;
                margin: 0 auto;
                ul{
                    li{
                        display: flex;
                        height:0.3rem;
                        justify-content: space-between;
                        align-items: center;
                        color:#1A2533;
                        font-size: 0.12rem;
                        padding-left: 0.1rem;
                        cursor:pointer;
                        &:hover{
                            background: #eee;
                        }
                        .img1{
                            height:0.11rem;
                            width:0.2rem;
                        }
                        input{
                            -webkit-appearance: none;
                            width: 0.18rem!important;
                            height: 0.17rem!important;
                            display: inline-block;
                            margin: -0.03rem 0.05rem 0 0!important;
                            cursor: pointer;
                            vertical-align: middle;
                            background:url(../assets/noccc.png) no-repeat;
                            &:checked {
                                background: url(../assets/okblue.png) no-repeat;
                            }
                            outline-color:none;
                            outline-style :none;
                            outline-width:none;
                        }
                    }
                }
            }
        }
    }
}
.foote{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.21rem;
    p{
        margin: 0 0.23rem;
        height:0.43rem;
        width:1.36rem;
        border-radius: 0.02rem;
        font-size: 0.14rem;
        line-height: 0.43rem;
        text-align: center;
        font-weight: bold;
        cursor:pointer;
    }
    .queding{
        color:#fff;
        background: #F96C6C;
    }
    .quxiao{
        color: #888888;
        border:0.01rem solid #888888;
        background: #fff;
    }
}
</style>
