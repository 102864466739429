  <template>
      <div class="wf-panel wf-widgetspanel">
          <div class="wf-panel-tab">
              <a href="javascript:;" class="tabitem current">控件</a>
          </div>
          <div class="wf-panel-body">
              <div v-for="(item,index) in components"
                  class="wf-widgetsitem" v-bind:data-index="index" v-bind:data-type="item.componentName" :key="index"
                  v-on:mousedown="start">
                  <label>
                      {{item.name}}
                  </label>
                  <i class="widgeticon" v-bind:class="item.componentName"></i>
              </div>

          </div>
      </div>
  </template>
  <script type="text/ecmascript-6">
  export default {
    name: 'mainleft',
    data () {
      return {
        components: [
          {
            name: '单行输入框',
            defaultLable: '单行输入框',
            defaultProps: '请输入',
            defaultImportant: false,
            siyou: '22',
            defaultPrint: false,
            componentName: 'textfield',
            supportSetting: ['label', 'placeholder1', 'required', 'important'],
            sort:''
          },
          {
            name: '序列号',
            defaultLable: '序列号',
            defaultProps: '请输入',
            defaultImportant: false,
            siyou: '22',
            defaultPrint: false,
            componentName: 'serialnum',
            supportSetting: ['label', 'placeholder1', 'required', 'important'],
            sort:''
          },
          {
            name: '多行输入框',
            defaultLable: '多行输入框',
            defaultProps: '请输入',
            siyou: '22',
            defaultImportant: false,
            defaultPrint: false,
            componentName: 'textareafield',
            supportSetting: ['label', 'placeholder1', 'required', 'important'],
            sort:''
          },
          {
            name: '单选框',
            defaultLable: '单选框',
            defaultProps: '请选择',
            siyou: '22',
            defaultImportant: false,
            zidingyi: '0',
            defaultSync: false,
            defaultPrint: false,
            defaultOptions: [
              { idx: 1, text: '选项1' },
              { idx: 2, text: '选项2' },
              { idx: 3, text: '选项3' }
            ],
            componentName: 'ddselectfield',
            supportSetting: ['label', 'placeholder1', 'options', 'required', 'important'],
            sort:''
          },
          {
            name: '多选框',
            defaultLable: '多选框',
            siyou: '22',
            zidingyi: '0',
            defaultProps: '请选择',
            defaultImportant: false,
            defaultPrint: false,
            defaultOptions: [
              { idx: 1, text: '选项1', zhi: false ,SettlementMethod:null,PrOfSettlement:0,Settlement_amt:0},
              { idx: 2, text: '选项2', zhi: false ,SettlementMethod:null,PrOfSettlement:0,Settlement_amt:0},
              { idx: 3, text: '选项3', zhi: false ,SettlementMethod:null,PrOfSettlement:0,Settlement_amt:0}
            ],
            componentName: 'ddmultiselectfield',
            supportSetting: ['label', 'placeholder1', 'options', 'required', 'important'],
            sort:''
          },
          {
            name: '日期',
            defaultLable: '日期',
            defaultProps: '请选择',
            defaultImportant: false,
            siyou: '22',
            defaultPrint: false,
            defaultFormat: 'yyyy-MM-dd',
            componentName: 'dddatefield',
            supportSetting: ['label', 'placeholder1', 'dateformat', 'required', 'important'],
            sort:''
          },
          {
            name: '日期区间',
            defaultLable: '时间区间',
            defaultLable1: '开始时间',
            defaultLable2: '结束时间',
            siyou: '22',
            defaultProps1: '请选择',
            defaultProps2: '请选择',
            defaultImportant: false,
            defaultPrint: false,
            // defaultAutorekonTime: false,
            defaultFormat: 'yyyy-MM-dd',
            // defaultSubtitle: '时长',
            // 'autorekonTime'
            componentName: 'dddaterangefield',
            supportSetting: [
              'label',
              'label1',
              'label2',
              'placeholder',
              'placeholder1',
              'placeholder2',
              'dateformat',
              'required',
              'important',
              'subtitle'],
              sort:''
          },
          // {
          //     name: '地点',
          //     defaultLable: '当前地点',
          //     siyou:'22',
          //     defaultImportant: false,
          //     defaultPrint: false,
          //     componentName: 'region',
          //     supportSetting: ['important']
          // },
          {
            name: '明细',
            defaultLable: '明细',
            defaultAction: '增加明细',
            // 'action',
            siyou: '22',
            components: [],
            selected: null,
            defaultPrint: false,
            InTableCanvas: null,
            componentName: 'tablefield',
            supportSetting: ['label', 'required'],
            sort:''
          },
          {
            name: '附件',
            defaultLable: '附件',
            siyou: '22',
            defaultImportant: false,
            defaultPrint: false,
            componentName: 'ddattachment',
            supportSetting: ['label', 'required', 'important'],
            sort:''
          },
          {
            name: '图片',
            defaultLable: '图片',
            siyou: '22',
            defaultImportant: false,
            defaultPrint: false,
            componentName: 'ddphotofield',
            supportSetting: ['label', 'required', 'important'],
            sort:''
          },
          {
            name: '说明文字',
            defaultLable: '说明文字',
            siyou: '22',
            defaultImportant: false,
            defaultProps: '请输入说明文字',
            defaultShow: false,
            defaultPrint: false,
            // defaultHref: '',
            // 'href'
            componentName: 'textnote',
            supportSetting: ['textnote', 'required', 'show', 'important'],
            sort:''
          },
          // {
          //     name: '角色',
          //     defaultLable: '角色',
          //     defaultProps: '请选择',
          //     siyou:'22',
          //     defaultPrint: false,
          //     defaultImportant: false,
          //     componentName: 'role',
          //     supportSetting: ['label', 'placeholder', 'required', 'important']
          // },
          {
            name: '部门',
            siyou: '22',
            defaultLable: '部门',
            defaultProps: '请选择',
            // dan_duoxuan:true,
            defaultPrint: false,
            defaultImportant: false,
            componentName: 'department',
            supportSetting: ['label', 'placeholder1', 'required', 'important'],
            sort:''
            // ,'radio'
          },
          {
            name: '联系人',
            defaultLable: '联系人',
            siyou: '22',
            defaultProps: '请选择',
            // dan_duoxuan:true,
            defaultPrint: false,
            defaultImportant: false,
            componentName: 'contacts',
            supportSetting: ['label', 'placeholder1', 'important', 'radio3'],
            sort:''
          },
          {
            name: '客户',
            defaultLable: '客户',
            siyou: '22',
            defaultProps: '请选择',
            // dan_duoxuan:true,
            defaultPrint: false,
            defaultImportant: false,
            componentName: 'cust',
            supportSetting: ['label', 'important'],
            sort:''
          },
          {
            name: '供应商名称',
            defaultLable: '供应商名称',
            defaultProps: '请输入',
            defaultImportant: false,
            siyou: '22',
            defaultPrint: false,
            componentName: 'suppliername',
            supportSetting: ['label', 'placeholder1', 'required', 'important'],
            sort:''
          },
          {
            name: '省市区',
            defaultLable: '省市区',
            siyou: '22',
            defaultProps: '请选择',
            // dan_duoxuan:true,
            defaultPrint: false,
            defaultImportant: false,
            componentName: 'province',
            supportSetting: ['label', 'important'],
            sort:''
          },
          {
            name: '关联审批单',
            defaultLable: '关联审批单',
            siyou: '22',
            defaultProps: '请选择',
            // dan_duoxuan:true,
            // guanlian_liebiao:true,
            guanlian_liebiao_list: [],
            defaultPrint: false,
            defaultImportant: false,
            componentName: 'assappform',
            supportSetting: ['label', 'placeholder1', 'required', 'important', 'guanlian_liebiao'],
            sort:''
          },
          {
            name: '产品',
            defaultLable: '产品',
            siyou: '22',
            defaultProps: '请选择',
            dan_duoxuan: true,
            defaultPrint: false,
            defaultImportant: false,
            componentName: 'product',
            supportSetting: ['label', 'placeholder1', 'required', 'important'],
            sort:''
            // ,'radio2'
          },
          { // 计算公式
            name: '计算公式',
            defaultLable: '计算公式',
            siyou: '22',
            defaultProps: '请选择',
            gongshi: '',
            defaultPrint: false,
            defaultImportant: false,
            componentName: 'calcform',
            supportSetting: ['label', 'placeholder1', 'required', 'translate', 'gongshi2'],
            sort:''
          },
          {
            name: '数字输入框',
            defaultLable: '数字输入框',
            siyou: '22',
            defaultProps: '请输入',
            defaultImportant: false,
            defaultPrint: false,
            componentName: 'numberfield',
            supportSetting: ['label', 'placeholder1', 'required', 'important', 'uint'],
            sort:''
          },
          {
            name: '金额',
            defaultLable: '金额（元）',
            siyou: '22',
            defaultProps: '请输入',
            defaultImportant: false,
            defaultTranslate: false,
            defaultPrint: false,
            componentName: 'moneyfield',
            supportSetting: ['label', 'placeholder1', 'translate', 'required', 'important'],
            sort:''
          }
        ]
      }
    },
    created () {
      let zhanshi_tubiao = true
      const biaodan_id = (this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi', '暂时') != undefined && this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi', '暂时').flow_id != undefined) ? this.$bendi_naqu_cunchu('mlbb_shenpi_zhengyi', '暂时').flow_id : ''
      if (biaodan_id == 'VMCizuVOXb' ||
                  biaodan_id == 'He5ln0bdDO' ||
                  biaodan_id == 'qIwxmsMTgv' ||
                  biaodan_id == 'Eb3n7CKQzi' ||
                  biaodan_id == 'PLOQ9ku3Ip' ||
                  biaodan_id == 'GBh7N2jMsi' ||
                  biaodan_id == 'cvRyWGiUQH' ||
                  biaodan_id == '7TvJHPyovM' ||
                  biaodan_id == 'Z7VDOK9HGu' ||
                  biaodan_id == '453y6pLD52' ||
                  biaodan_id == '5FotYgnu0Z' ||
                  biaodan_id == 'u1SCMMoUwK' ||
                  biaodan_id == 'Xsk2pLBS9a' ||
                  biaodan_id == 'vY9ubhTO89' ||
                  biaodan_id == 'fZ0x5qedzn' ||
                  biaodan_id == 'hXstgwxhzh' || //AY3cli7BAb
                  biaodan_id == 'H49d4edc3T' ||
                  biaodan_id == 'AY3cli7BAb'
      ) {
        zhanshi_tubiao = false
      } else {
        zhanshi_tubiao = true
      }
      if (!zhanshi_tubiao) {
        for (let i = 0; i < this.components.length; i++) {
          if (this.components[i].componentName == 'department' || this.components[i].componentName == 'contacts' || this.components[i].componentName == 'cust') {
            this.components.splice(i, 1)
            i = i - 1
          }
        }
      }
    },
    methods: {
      start: function (e) {
        const obj = {}
        const dom = e.currentTarget
        const index = dom.getAttribute('data-index')
        let actualLeft = dom.offsetLeft
        let current = dom.offsetParent
        let actualTop = dom.offsetTop
        while (current !== null) {
          actualLeft += current.offsetLeft
          actualTop += current.offsetTop
          current = current.offsetParent
        }
        obj.componentName = dom.getAttribute('data-type')
        obj.componentText = dom.querySelector('label').innerText
        obj.clientX = e.clientX
        obj.clientY = e.clientY
        obj.isstart = true
        obj.componentView = this.components[index]
        console.log(obj)
        drag.$emit('movestart', obj)
      }
    }
  }
  </script>
