<template>
    <div>
      <div class="fd-nav-content">
        <section class="dingflow-design">
          <div class="zoom">
            <div :class="'zoom-out'+ (nowVal==50?' disabled':'')" @click="zoomSize(1)"></div>
            <span>{{nowVal}}%</span>
            <div :class="'zoom-in'+ (nowVal==300?' disabled':'')" @click="zoomSize(2)"></div>
          </div>
          <div class="box-scale" id="box-scale" :style="'transform: scale('+nowVal/100+'); transform-origin: 50% 0 0;'">
            <nodeWrap :nodeConfig.sync="nodeConfig" :flowPermission.sync="flowPermission"
            :isTried.sync="isTried" :directorMaxLevel="directorMaxLevel" :tableId="tableId"></nodeWrap>
            <div class="end-node">
                <div class="end-node-circle"></div>
                <div class="end-node-text">流程结束</div>
            </div>
          </div>
        </section>
      </div>
      <el-dialog title="提示" :visible.sync="tipVisible">
        <div class="ant-confirm-body">
          <i class="anticon anticon-close-circle" style="color: #f00;"></i>
          <span class="ant-confirm-title">当前无法发布</span>
          <div class="ant-confirm-content">
            <div>
              <p class="error-modal-desc">以下内容不完善，需进行修改</p>
              <div class="error-modal-list">
                <div class="error-modal-item" v-for="(item,index) in tipList" :key="index">
                  <div class="error-modal-item-label">流程设计</div>
                  <div class="error-modal-item-content">{{item.name}} 未选择{{item.type}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="tipVisible = false">我知道了</el-button>
          <el-button type="primary" @click="tipVisible = false">前往修改</el-button>
        </span>
      </el-dialog>
    </div>
</template>
<script>
import { insert_flow_action_info } from '../../api/api'
export default {
  components: {},
  data () {
    return {
      isTried: false,
      tipList: [],
      tipVisible: false,
      nowVal: 100,
      processConfig: {},
      nodeConfig: {
        childNode: null,
        conditionList: [],
        conditionNodes: [],
        nodeName: '发起人',
        nodeUserList: [],
        type: '0',
        id: this.randomstring(10)
      },
      flowPermission: [], // 发起人的列表
      directorMaxLevel: 20, // 有多少个主管  现在是写死的
      dialogVisible: true,
      tableId: '',
      chuanshu_list: []
      // zi_id:1
    }
  },
  watch: {
    nodeConfig: {
      handler (newName, oldName) {
        this.$bendi_cunfang_cunchu('mlbb_liucheng_shezhi', this.nodeConfig, '暂时')
      },
      deep: true
    },
    flowPermission: {
      handler (newName, oldName) {
        this.$bendi_cunfang_cunchu('mlbb_liucheng_shezhi_flowPermission', this.flowPermission, '暂时')
      },
      deep: true
    },
    tableId: {
      handler (newName, oldName) {
        this.$bendi_cunfang_cunchu('mlbb_liucheng_shezhi_tableId', this.tableId, '暂时')
      },
      deep: true
    },
    chuanshu_list: {
      handler (newName, oldName) {
        this.$bendi_cunfang_cunchu('mlbb_liucheng_shezhi_list', this.chuanshu_list, '暂时')
      },
      deep: true
    }
  },
  created () {
    if (this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi', '暂时') != undefined) {
      this.nodeConfig = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi', '暂时')
    } else {
      this.$bendi_cunfang_cunchu('mlbb_liucheng_shezhi', this.nodeConfig, '暂时')
    }
    if (this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_flowPermission', '暂时') != undefined) {
      this.flowPermission = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_flowPermission', '暂时')
    } else {
      this.$bendi_cunfang_cunchu('mlbb_liucheng_shezhi_flowPermission', this.flowPermission, '暂时')
    }
    if (this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_tableId', '暂时') != undefined) {
      this.tableId = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi_tableId', '暂时')
    } else {
      this.$bendi_cunfang_cunchu('mlbb_liucheng_shezhi_tableId', this.tableId, '暂时')
    }
    // this.$axios.get("/data.json", {
    //     workFlowDefId: this.$route.params.workFlowDefId
    // }).then(res => {
    //     this.processConfig = res.data;
    //     this.nodeConfig = this.processConfig.nodeConfig;
    //     this.flowPermission = this.processConfig.flowPermission;
    //     this.directorMaxLevel = this.processConfig.directorMaxLevel;
    //     this.workFlowDef = this.processConfig.workFlowDef
    //     this.tableId = this.processConfig.tableId
    // })
  },
  methods: {
    cunfang_tree () {
      if (this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi', '暂时') != undefined) {
        this.nodeConfig = this.$bendi_naqu_cunchu('mlbb_liucheng_shezhi', '暂时')
      }
    },
    randomstring (L) {
      var s = ''
      var randomchar = function () {
        var n = Math.floor(Math.random() * 62)
        if (n < 10) return n // 1-10
        if (n < 36) return String.fromCharCode(n + 55) // A-Z
        return String.fromCharCode(n + 61) // a-z
      }
      while (s.length < L) s += randomchar()
      return s
    },
    reErr (data) {
      if (data.childNode) {
        if (data.childNode.type == 1) { // 审批人
          if (data.childNode.error) {
            this.tipList.push({ name: data.childNode.nodeName, type: '审核人' })
          }
          this.reErr(data.childNode)
        } else if (data.childNode.type == 2) {
          if (data.childNode.error) {
            this.tipList.push({ name: data.childNode.nodeName, type: '抄送人' })
          }
          this.reErr(data.childNode)
        } else if (data.childNode.type == 9) {
          if (data.childNode.error == undefined) {
            this.tipList.push({ name: data.childNode.nodeName, type: '归档人' })
          }
          this.reErr(data.childNode)
        } else if (data.childNode.type == 3) {
          this.reErr(data.childNode.childNode)
        } else if (data.childNode.type == 4) {
          this.reErr(data.childNode)
          for (var i = 0; i < data.childNode.conditionNodes.length; i++) {
            if (data.childNode.conditionNodes[i].error) {
              this.tipList.push({ name: data.childNode.conditionNodes[i].nodeName, type: '条件' })
            }
            this.reErr(data.childNode.conditionNodes[i])
          }
        }
      } else {
        data.childNode = null
      }
    },
    tiqu (data) {
      for (let i = 0; i < data.length; i++) {
        const tiaojianname = ''
        const ids = ''
        if (data[i].conditionList.length != 0) {
          const list = []
          for (let a = 0; a < data[i].conditionList.length; a++) {
            if (data[i].conditionList[a].showName == '发起人') {
              list.push(
                {
                  cdt_type: '1',
                  compare_value: data[i].nodeUserList.map((i, index) => { return i.targetId }).join(','),
                  compare_type: '6',
                  cdt_field: 'start_id'
                }
              )
            }
          }
          if (data[i].childNode != null) {
            this.chuanshu_list.push({
              ent_id: this.$ent_id(),
              action_name: data[i].nodeName,
              priority_level: data[i].priorityLevel + '',
              cdt_list: list,
              action_id: data[i].id,
              child_id: data[i].childNode.id
            })
          } else {
            this.chuanshu_list.push({
              ent_id: this.$ent_id(),
              action_name: data[i].nodeName,
              priority_level: data[i].priorityLevel + '',
              cdt_list: list,
              action_id: data[i].id
            })
          }

          console.log(data[i])
          this.shuzu(data[i])
        }
      }
    },
    // 传出整个数组
    shuzu (data) {
      if (data.childNode) {
        if (data.childNode.type == 1) { // 审批人
          var renyuan_ids = ''
          var juese_ids = ''
          let hui_or_huo = ''
          let zhuguan = ''
          let who = ''
          if (data.childNode.settype == 1 || data.childNode.settype == 8) {
            hui_or_huo = data.childNode.examineMode + ''
          } else {
            hui_or_huo = null
          }
          if (data.childNode.settype == 1) {
            renyuan_ids = data.childNode.nodeUserList.map((i, index) => { return i.targetId }).join(',')
            juese_ids = null
            who = '1'
          } else if (data.childNode.settype == 8) {
            renyuan_ids = null
            juese_ids = data.childNode.nodeUserList.map((i, index) => { return i.targetId }).join(',')
            who = '3'
          }
          if (data.childNode.settype == 2) {
            zhuguan = data.childNode.directorLevel
            who = '2'
          } else {
            zhuguan = null
          }
          if (data.childNode.childNode != null) {
            this.chuanshu_list.push({
              ent_id: this.$ent_id(),
              action_name: data.childNode.nodeName,
              action_type: '1',
              user_ids: renyuan_ids,
              role_ids: juese_ids,
              action_id: data.childNode.id,
              child_id: data.childNode.childNode.id,
              people_appr_type: hui_or_huo,
              manager: zhuguan,
              appr_user_type: who
            })
          } else {
            this.chuanshu_list.push({
              ent_id: this.$ent_id(),
              action_name: data.childNode.nodeName,
              action_type: '1',
              user_ids: ids,
              action_id: data.childNode.id,
              people_appr_type: hui_or_huo,
              manager: zhuguan,
              appr_user_type: who
            })
          }
          if (data.childNode != null) {
            this.shuzu(data.childNode)
          }
        } else if (data.childNode.type == 2) { // 抄送人
          if (data.childNode.childNode != null) {
            var ids = data.childNode.nodeUserList.map((i, index) => { return i.targetId }).join(',')
            if (ids == '') {
              ids = null
            }
            this.chuanshu_list.push({
              ent_id: this.$ent_id(),
              action_name: data.childNode.nodeName,
              action_type: '2',
              user_ids: ids,
              action_id: data.childNode.id,
              child_id: data.childNode.childNode.id,
              self_select: data.childNode.ccSelfSelectFlag + ''
            })
          } else {
            this.chuanshu_list.push({
              ent_id: this.$ent_id(),
              action_name: data.childNode.nodeName,
              action_type: '2',
              user_ids: ids,
              action_id: data.childNode.id,
              self_select: data.childNode.ccSelfSelectFlag + ''
            })
          }
          if (data.childNode != null) {
            this.shuzu(data.childNode)
          }
        } else if (data.childNode.type == 9) { // 归档人
          if (data.childNode.childNode != null) {
            var ids = data.childNode.nodeUserList.map((i, index) => { return i.targetId }).join(',')
            this.chuanshu_list.push({
              ent_id: this.$ent_id(),
              action_name: data.childNode.nodeName,
              action_type: '3',
              user_ids: ids,
              action_id: data.childNode.id,
              child_id: data.childNode.childNode.id
            })
          } else {
            this.chuanshu_list.push({
              ent_id: this.$ent_id(),
              action_name: data.childNode.nodeName,
              action_type: '3',
              user_ids: ids,
              action_id: data.childNode.id
            })
          }
          if (data.childNode != null) {
            this.shuzu(data.childNode)
          }
        } else if (data.childNode.type == 3) {

        } else if (data.childNode.type == 4) { // 条件
          // let ziid=this.tiaojian(data.childNode.conditionNodes)+''
          if (data.childNode.childNode != null) {
            var tiaojianid = data.childNode.conditionNodes.map((i, index) => {
              return i.id
            }).join(',')
            this.chuanshu_list.push({
              ent_id: this.$ent_id(),
              action_name: data.childNode.nodeName,
              action_type: '5',
              action_id: data.childNode.id,
              cdt_ids: tiaojianid,
              child_id: data.childNode.childNode.id
            })
          } else {
            var tiaojianid = data.childNode.conditionNodes.map((i, index) => {
              return i.id
            }).join(',')
            this.chuanshu_list.push({
              ent_id: this.$ent_id(),
              action_name: data.childNode.nodeName,
              action_type: '5',
              action_id: data.childNode.id,
              cdt_ids: tiaojianid
            })
          }
          this.tiqu(data.childNode.conditionNodes)
          if (data.childNode != null) {
            this.shuzu(data.childNode)
          }
        }
      } else {
        data.childNode = null
      }
    },
    saveSet () {
      this.isTried = true
      this.tipList = []
      this.reErr(this.nodeConfig)
      if (this.tipList.length != 0) {
        this.tipVisible = true
        return 2
      } else {
        this.chuanshu_list = []
        this.processConfig.flowPermission = this.flowPermission
        let what = ''
        var ids = this.flowPermission.map((i, index) => {
          return i.targetId
        }).join(',')
        if (ids == '') {
          ids = null
          what = '1'
        } else {
          what = '0'
        }
        if (this.nodeConfig.childNode == null) {
          this.chuanshu_list.push({
            action_name: '发起人',
            action_type: '0',
            all_user_flag: what,
            user_ids: ids,
            action_id: this.nodeConfig.id
          })
        } else {
          this.chuanshu_list.push({
            action_name: '发起人',
            action_type: '0',
            all_user_flag: what,
            user_ids: ids,
            child_id: this.nodeConfig.childNode.id,
            action_id: this.nodeConfig.id
          })
        }
        this.shuzu(this.nodeConfig)
      }
    },
    zoomSize (type) {
      if (type == 1) {
        if (this.nowVal == 50) {
          return
        }
        this.nowVal -= 10
      } else {
        if (this.nowVal == 300) {
          return
        }
        this.nowVal += 10
      }
    }
  }
}
</script>
<style >
    @import "../../css/workflow.css";
</style>
<style scoped lang="scss">
.v-modal{
  display: none!important;
}
</style>
